import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CanvasJSReact from '@canvasjs/react-charts';
import './App.css';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Home = () => {
  const [IrrigationLoad, setIrrigationLoad] = useState([]);
  const [RunningGDD, setRunningGDD] = useState(null); // New state to store RunningGDD

  const fetchIrrigationLoad = async () => {
    try {
      const response = await axios.get('/IrrigationLoad');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW,
		  reporting: item.REPORTING,
		  GDD: item.GDD,
		  RunningGDD: item.RunningGDD
        }));
        setIrrigationLoad(formattedData);
		const latestRunningGDD = response.data.length > 0 ? response.data[response.data.length - 1].RunningGDD : null;
        setRunningGDD(latestRunningGDD);
      }
    } catch (err) {
      console.log('Error fetching Irrigation Load', err);
    }
  };
  
  const [A1, setA1] = useState([]);

  const fetchA1 = async () => {
    try {
      const response = await axios.get('/A1');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setA1(formattedData);
      }
    } catch (err) {
      console.log('Error fetching A1 Load', err);
    }
  };
  
  const [A2, setA2] = useState([]);

  const fetchA2 = async () => {
    try {
      const response = await axios.get('/A2');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setA2(formattedData);
      }
    } catch (err) {
      console.log('Error fetching A2 Load', err);
    }
  };
  
  const [A3, setA3] = useState([]);

  const fetchA3 = async () => {
    try {
      const response = await axios.get('/A3');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setA3(formattedData);
      }
    } catch (err) {
      console.log('Error fetching A3 Load', err);
    }
  };
  
  const [A4, setA4] = useState([]);

  const fetchA4 = async () => {
    try {
      const response = await axios.get('/A4');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setA4(formattedData);
      }
    } catch (err) {
      console.log('Error fetching A4 Load', err);
    }
  };
  
  const [A5, setA5] = useState([]);

  const fetchA5 = async () => {
    try {
      const response = await axios.get('/A5');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setA5(formattedData);
      }
    } catch (err) {
      console.log('Error fetching A5 Load', err);
    }
  };
  
  const [A6, setA6] = useState([]);

  const fetchA6 = async () => {
    try {
      const response = await axios.get('/A6');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setA6(formattedData);
      }
    } catch (err) {
      console.log('Error fetching A6 Load', err);
    }
  };
  
   const [B11, setB11] = useState([]);
   
    const fetchB11 = async () => {
    try {
      const response = await axios.get('/B11');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setB11(formattedData);
      }
    } catch (err) {
      console.log('Error fetching B11 Load', err);
    }
  };
  
	const [C21, setC21] = useState([]);
  
    const fetchC21 = async () => {
    try {
      const response = await axios.get('/C21');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setC21(formattedData);
      }
    } catch (err) {
      console.log('Error fetching C21 Load', err);
    }
  };
  
  const [NoControl, setNoControl] = useState([]);
  
    const fetchNoControl = async () => {
    try {
      const response = await axios.get('/NoControl');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW
        }));
        setNoControl(formattedData);
      }
    } catch (err) {
      console.log('Error fetching NoControl Load', err);
    }
  };
  
    const [SysLoad, setSysLoad] = useState([]);
  
    const fetchSysLoad = async () => {
    try {
      const response = await axios.get('/SystemLoad');
      console.log(response.data); // Log the response data
      if (Array.isArray(response.data)) {
        const formattedData = response.data.map(item => ({
          x: new Date(item.TS),
          y: item.KW,
		  reporting: item.REPORTING
        }));
        setSysLoad(formattedData);
      }
    } catch (err) {
      console.log('Error fetching SysLoad Load', err);
    }
  };

  useEffect(() => {
	document.title = "Irrigation Load Dashboard";
	fetchSysLoad();
    fetchIrrigationLoad();
	fetchA1();
	fetchA2();
	fetchA3();
	fetchA4();
	fetchA5();
	fetchA6();
	fetchB11();
	fetchC21();
	fetchNoControl();
  }, []);
  
  const navigateToSubInfo = () => {
    window.location.href = 'https://sub.northeastpow.info';
  };

  const totalOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "Total Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: IrrigationLoad,
	  toolTipContent: "Date: {x}<br/>KW: {y}<br/>Reporting: {reporting}<br/>GDD: {GDD}<br/>Total GDD for Season: {RunningGDD}"
    }]
  };

  const A1Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "A1 Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: A1
    }]
  };
  
    const A2Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "A2 Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: A2
    }]
  };
  
      const A3Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "A3 Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: A3
    }]
  };
  
      const A4Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "A4 Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: A4
    }]
  };
  
      const A5Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "A5 Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: A5
    }]
  };
  
      const A6Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "A6 Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: A6
    }]
  };
  
        const B11Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "B11 Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: B11
    }]
  };
  
        const C21Op = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "C21 Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: C21
    }]
  };
  
        const NoControlOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "No Control Irrigation Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: NoControl
    }]
  };
  
          const SysLoadOp = {
	zoomEnabled:true,
    theme: "light2",
    title: {
      text: "Total System Load"
    },
    axisX: {
      title: "Date",
      valueFormatString: "DD MMM YYYY"
    },
    axisY: {
      title: "KW",
      includeZero: false
    },
    data: [{
      type: "line",
      xValueFormatString: "DD MMM YYYY HH:mm",
      yValueFormatString: "#,##0.## KW",
      dataPoints: SysLoad,
	  toolTipContent: "Date: {x}<br/>KW: {y}<br/>Reporting: {reporting}"
    }]
  };
  
 return (
    <div>
	  <div className="header">
      <p>NORTHEAST POWER LOAD</p>
      <p>79.7mw MAX System Load</p>
      <p>Irrigation MAX = 26mw + 3.9mw reserve = 30.2mw</p>
      <p>5mw wind load built into System Load.</p>
	  {RunningGDD && <p>Total Running GDD for the season: {RunningGDD}</p>}
	  </div>
	  <div className="button">
		<button onClick={navigateToSubInfo}>Substation Load Dashboard</button>
	  </div>
      <div className="charts-container">
        <div className="chart-item"><CanvasJSChart options={SysLoadOp} /></div>
        <div className="chart-item"><CanvasJSChart options={totalOp} /></div>
        <div className="chart-item"><CanvasJSChart options={A1Op} /></div>
        <div className="chart-item"><CanvasJSChart options={A2Op} /></div>
        <div className="chart-item"><CanvasJSChart options={A3Op} /></div>
        <div className="chart-item"><CanvasJSChart options={A4Op} /></div>
        <div className="chart-item"><CanvasJSChart options={A5Op} /></div>
        <div className="chart-item"><CanvasJSChart options={A6Op} /></div>
        <div className="chart-item"><CanvasJSChart options={B11Op} /></div>
        <div className="chart-item"><CanvasJSChart options={C21Op} /></div>
        <div className="chart-item"><CanvasJSChart options={NoControlOp} /></div>
      </div>
    </div>
  );
};

export default Home;
